<template>
    <div>
        <div v-if="isAppLoaded">
            <v-progress-linear indeterminate/>
        </div>
        <div v-else>
            {{ $t('globals.redirect') }}
        </div>
    </div>
</template>
<script>
import NextOrRedirect from '@/shared/mixins/NextOrRedirect'

export default {
    name: 'LoadingView',
    mixins: [ NextOrRedirect ],
    data: vm => ({
        isLoadingNextRoute: false,
    }),
    created() {
        document.title = this.$t('globals.loading')
    },
    methods: {
        nextRoute() {
            this.isLoadingNextRoute = true
            this.nextOrRedirect({ name: 'home' })
        },
    },
    computed: {
        isAppLoaded() {
            let value = this.$store.getters['app/isLoaded']

            if (value && !this.isLoadingNextRoute)
                this.nextRoute()

            return value
        },
    },
}
</script>